import React from "react";

import { BookView } from '../ItemViews/BookView';
import { AudioView } from '../ItemViews/AudioView';
import { LoadingView } from '../ItemViews/LoadingView';
import { Sidebar } from './Sidebar/Sidebar';
import { InfoDisplay } from "./InfoDisplay";
import { SerialView } from "../ItemViews/SerialView";
import { NotesView } from "../ItemViews/NotesView";

class SubmissionFormPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            forceShowErrors: false
        }
    }
    nextPageAllowed = () => {
        let allowed = true;
        
        this.props.items.forEach((f) => {
            if(!f.isValid()) {
                allowed = false;
            }
        });

        return allowed;
    }

    next = () => {
        if(this.nextPageAllowed()) this.props.appFunctions.selectPage("contact");
        else this.setState({forceShowErrors: true}); // When continue is clicked, we will highlight every error on the page
    }

    selectView = () => {
        if(this.props.item.loading) {
            return <LoadingView />
        }
        switch(this.props.item.publicationType) {
            case "book":
                return <BookView item={this.props.item} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
            case "audio":
                return <AudioView item={this.props.item} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
            case "serial":
                return <SerialView item={this.props.item} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
            case "notes":
                return <NotesView item={this.props.item} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} />
            default:
                return <BookView item={this.props.item} appFunctions={this.props.appFunctions} forceShowErrors={this.state.forceShowErrors} /> // default to book for now...
        }
    }
  
    render() {
        let view = this.props.item ? this.selectView() : <InfoDisplay />;
        let appFuncs = Object.assign( this.props.appFunctions, { nextPage: this.next });
        return (
            <div>
                <Sidebar showNext={this.props.items.length > 0} items={this.props.items} selectedItem={this.props.item} appFunctions={appFuncs} forceShowErrors={this.state.forceShowErrors} contactPerson={this.props.contactPerson} />
                <div className="content-view">
                    <div className="publication-info">
                        {view}
                    </div>
                </div>
            </div>
        );
    }
  }

  export { SubmissionFormPage }
