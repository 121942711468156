import './Styles/App.css';

import React from "react"

import { SubmissionFormPage } from './Pages/SubmissionFormPage'
import { ConfirmationPage } from './Pages/ConfirmationPage'
import { ContactInfoPage } from './Pages/ContactInfoPage'
import { FaqDisplay } from './Pages/FaqDisplay'
import { SuccessPage } from './Pages/SuccessPage';
import { Navbar } from './Pages/Navbar';
import { Item } from './Models/Item';
import { FileInfo} from './Models/FileInfo';
import { ModalMessage } from './FormElements/ModalMessage';
import { LoadingBar } from './FormElements/LoadingBar';
import { FeedbackFormDisplay } from './Pages/FeedbackFormDisplay';
import lang  from './Localization';

class App extends React.Component {
    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            page: "files",
            language: "fi",
            items: [],
            selectedItem: null,
            contactPerson: {
                person: "",
                email: "",
                phone: "",
                organization: "",
                submissionID: urlParams.get("submission-id") ? urlParams.get("submission-id") : ""
            },

            // Modal message stuff
            showModal: false,
            modalMessage: "",
            modalButtons: [],
            showFaqForm: false,
            showFeedbackForm: false
        }

        this.appFunctions = {
            selectPage: this.selectPage,
            addItem: this.addItem,
            setItemField: this.setItemField,
            deleteItem: this.deleteItem,
            selectItem: this.selectItem,
            createItem: this.createItem,
            itemUpdated: this.itemUpdated,
            getMetadataForItem: this.getMetadataForItem,
            addFileToItem: this.addFileToItem,
            updateContactPerson: this.updateContactPerson,
            sendForm: this.sendForm,
            setLanguage: this.setLanguage,
            reset: this.reset,
            showModalMessage: this.showModalMessage,
            hideModalMessage: this.hideModalMessage,
            openFaqForm: this.openFaqForm,
            closeFaqForm: this.closeFaqForm,
            openFeedbackForm: this.openFeedbackForm,
            closeFeedbackForm: this.closeFeedbackForm
        };

        // An initial language might be given as a url parameter
        const l = urlParams.get("lang") ? urlParams.get("lang") : "fi"; // default is Finnish
        this.setLanguage(l);
    }

    reset = () => {
        this.setState({
            page: "files",
            items: [],
            selectedItem: null,
            showFaqForm: false,
            showFeedbackForm: false
        });
    }

    setLanguage = (language) => {
        lang.setLanguage(language);
        this.setState({language: language});
    }

    sendForm = async () => {
        await this.props.backend.submitData(this.state.items, this.state.contactPerson, lang.getLanguage());
        this.selectPage("success");
    }

    updateContactPerson = (field, value) => {
        const contactPerson = Object.assign({}, this.state.contactPerson);
        contactPerson[field] = value;
            this.setState((state) => {
                return {
                    contactPerson: contactPerson
                }
            });
    }

    getMetadataForItem = async (item, field, query) => {
        if(!item.fields[field]) item.fields[field] = {};
        item.fields[field].loading = true;
        item.fields[field].done = false;
        
        let metadata = await this.props.backend.getMetadata(field, query);
        
        item.fields[field].loading = false;
        item.fields[field].done = true;

        item.setMetadataFromQuery(field, metadata.data);
        this.itemUpdated(item);
        return metadata;
    }

    selectPage = (page) => {
        this.setState({ page: page });
    }

    createItem = (type, data) => {
        let item = Item.createType(type, data);
        return item;
    }

    showModalMessage = (message, buttons) => {
        this.setState({
            showModal: true,
            modalMessage: message,
            modalButtons: buttons
        })
    }
    hideModalMessage = () => {
        this.setState({
            showModal: false
        })
    }

    openFaqForm = () => {
        this.setState({ showFaqForm: true });
    }
    closeFaqForm = () => {
        this.setState({ showFaqForm: false });
    }

    openFeedbackForm = () => {
        this.setState({ showFeedbackForm: true });
    }
    closeFeedbackForm = () => {
        this.setState({ showFeedbackForm: false });
    }

    addFileToItem = async (fileData, item, purpose) => {
        purpose = purpose || "main";
        
        const file = new FileInfo({ originalFilename: fileData.name, purpose: purpose, data: fileData });

        try {
            let resp = await this.props.backend.sendFileToBackend(file, item.publicationType);

            file.tmpFilename = resp.data.tmpFilename;
            file.ok = true;
            item.addFile(file, resp.data);
            if(purpose === "main" && resp.data.isbn) {
                resp.data.isbn = resp.data.isbn.trim(); // Seems sometimes the found ISBN contains extra whitespace
                this.setItemField(item.id, "isbn", resp.data.isbn)
                
                try {
                    this.getMetadataForItem(item, "isbn", resp.data.isbn); // Try to find more info right away
                } catch(error) {
                    // Getting the metadata failed... Well, nothing to do then
                }
            }
        } catch(error) {
            // If we get no response from the server, we show a default error message
            let errorMsg = lang.errors.uploadFailed;
            if(error.response) {
                let errorMessages = [];
                error.response.data.errors.forEach((e) => {
                    errorMessages.push( lang.errors[e] ? lang.errors[e] : e ); // Push either a translation or the error message to the messages to be shown
                });
                errorMsg = errorMessages.join(" ");
            }
            this.showModalMessage(errorMsg, [{ text: lang.close, onClick: this.hideModalMessage }])
        } finally {
            item.loading = false;

            // if the item has no files when we get here, the uploading failed and it was the item's only file
            // In this case we delete the item so the user can try uploading another file or selecting another type
            if(item.getFiles("main").length === 0) {
                this.deleteItem(item);
            }
            else {
                this.itemUpdated(item);
            }
        }
    }

    addItem = async (item) => {
        this.setState((state) => {
            return {
                items: state.items.concat(item),
                selectedItem: item
            }
        });
        this.selectPage("files");
    }

    deleteItem = (item) => {
        this.setState((state) => {
            // Just filter the item away from the list
            let items = state.items.filter((it) => {
                return it.id !== item.id;
            });

            let selectedItem = items.length > 0 ? items[items.length-1] : null;

            return {
                items: items,
                selectedItem: selectedItem
            }
        });
    }

    selectItem = (item) => {
        this.setState((state) => {
            return {
                selectedItem: item
            }
        })
    }

    // A function to just refresh the state when an item has been upated by a child component
    itemUpdated = (item) => {
        this.setState((state) => {
            return {
                items: state.items.map((it) => it.id === item.id ? item : it)
            };
        });
    }

    setItemField = (id, field, value) => {
        let i = this.state.items.find((item) => { return item.id === id });
        if(i) {
            i[field] = value;
            this.itemUpdated(i);
        }
    }

    render = () => {
        document.title = lang.mainTitle;

        let shownPage;
        switch(this.state.page) {
            case "contact":
                shownPage = <ContactInfoPage appFunctions={this.appFunctions} contactPerson={this.state.contactPerson} />
                break;
            case "confirm":
                shownPage = <ConfirmationPage appFunctions={this.appFunctions} contactPerson={this.state.contactPerson} items={this.state.items} />
                break;
            case "success":
                shownPage = <SuccessPage appFunctions={this.appFunctions} />
                break;
            case "files":
            default:
                shownPage = <SubmissionFormPage items={this.state.items} appFunctions={this.appFunctions} item={this.state.selectedItem} contactPerson={this.state.contactPerson} />
        }

        return (
            <div className="LomakeApp">
                <Navbar page={this.state.page} appFunctions={this.appFunctions} />
                <LoadingBar backend={this.props.backend} />

                <div className="main-content">
                    {shownPage}
                </div>

                <FaqDisplay show={this.state.showFaqForm} appFunctions={this.appFunctions} backend={this.props.backend} />
                <FeedbackFormDisplay show={this.state.showFeedbackForm} appFunctions={this.appFunctions} backend={this.props.backend} />
                <ModalMessage show={this.state.showModal} message={this.state.modalMessage} buttons={this.state.modalButtons} />

            </div>
        );
    }
}

export default App;
