import dataid from './DataIdentifier';

class Author {
    constructor(data) {
        data = data || {};

        this.id = data.id || dataid(); // If id not given, generate one
        this.name = data.name || "";
        
        // book roles
        this.writer = data.writer || false;
        this.editor = data.editor || false;
        this.translator = data.translator || false;
        this.illustrator = data.illustrator || false;

        // audio & notes roles
        this.artist = data.artist || false;
        this.composer = data.composer || false;
        this.lyricist = data.lyricist || false;
        this.arranger = data.arranger || false;
    }

    // Function that copies info to this author from the given author (except the id)
    copy(data) {
        this.name = data.name || "";
        
        // book roles
        this.writer = data.writer || false;
        this.editor = data.editor || false;
        this.translator = data.translator || false;
        this.illustrator = data.illustrator || false;

        // audio & notes roles
        this.artist = data.artist || false;
        this.composer = data.composer || false;
        this.lyricist = data.lyricist || false;
        this.arranger = data.arranger || false;
    }

    /**
     * Function to get only the data without the functions
     * NOTE: the data also contains a bit different keys for some items because that's what Varsta expects
     */
    getSubmittableData = () => {
        return {
            first: this.name, // Currently varsta expects full name at key "first", (might be a good TODO to change this...)
            writer: this.writer,
            editor: this.editor,
            translator: this.translator,
            illustrator: this.illustrator,
            artist: this.artist,
            composer: this.composer,
            lyricist: this.lyricist,
            arranger: this.arranger
        }
    }
}

export { Author }