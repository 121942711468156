import React from "react"

import {FormControl, InputGroup} from 'react-bootstrap';

class ContactFormField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldDirty: false
        };
    }
    setValue = (ev) => {
        const target = ev.target;
        const field = target.name;
        const value = target.value;

        this.props.appFunctions.updateContactPerson(field, value);
    }

    setDirty = () => {
        this.setState({fieldDirty: true});
    }
  
    render() {
        const requiredInfo = this.props.required ? <span style={{color: "red"}}>*</span> : ""
        return (
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text className="input-group-text-form">
                        {this.props.labelText}
                        {requiredInfo}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    className={this.props.required && (this.props.forceShowErrors || this.state.fieldDirty) && !this.props.contactPerson[this.props.field] ? "input-error" : ""}
                    name={this.props.field}
                    value={this.props.contactPerson[this.props.field]}
                    aria-label={this.props.labelText}
                    onChange={this.setValue}
                    onBlur={this.setDirty}
                    disabled={this.props.disabled}
                />
            </InputGroup>
        );
    }
  }

  export { ContactFormField }